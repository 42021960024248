<template>
  <b-modal
    id="broker-modal"
    centered
    ok-only
    title="Изменить данные брокера"
    @ok="updateBroker"
  >
    <b-card-text>
      <b-row>
        <b-col>
          <b-form-group label="Имя">
            <b-form-input v-model="brokerForm.first_name" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Фамилия">
            <b-form-input v-model="brokerForm.last_name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Город">
            <b-form-input v-model="brokerForm.city" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Телефон">
            <b-form-input v-model="brokerForm.phone" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Email">
            <b-form-input v-model="brokerForm.email" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
export default {
  name: 'BrokerModal',
  props: ['brokerData'],
  data() {
    return {
      brokerForm: {},
    }
  },
  watch: {
    brokerData() {
      this.brokerForm = this.brokerData
    },
  },
  methods: {
    async updateBroker() {
      await this.$http.patch(
        `admin/brokers/${this.brokerForm.id}/`,
        this.brokerForm
      )
      this.$emit('brokerUpdated')
    },
  },
}
</script>
