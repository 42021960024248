<template>
  <b-row>
    <b-row v-if="selectedCompany" class="mb-2 ml-1">
      <b-col class="pl-0" cols="6" md="4" sm="4">
        <b-form-group label="Поиск по номеру телефона">
          <b-form-input
            v-model="phoneSearch"
            debounce="500"
            placeholder="Поиск по номеру телефона..."
          />
        </b-form-group>
      </b-col>
      <b-col class="pl-0" cols="6" md="4" sm="4">
        <b-form-group label="Поиск по имени">
          <b-form-input
            v-model="firstNameSearch"
            debounce="500"
            placeholder="Поиск по имени..."
          />
        </b-form-group>
      </b-col>
      <b-col class="pl-0" cols="6" md="4" sm="4">
        <b-form-group label="Поиск по фамилии">
          <b-form-input
            v-model="lastNameSearch"
            debounce="500"
            placeholder="Поиск по фамилии..."
          />
        </b-form-group>
      </b-col>
      <b-col class="pl-0" cols="6" md="4" sm="4">
        <b-form-group label="Поиск по городу">
          <b-form-input
            v-model="citySearch"
            debounce="500"
            placeholder="Поиск по городу..."
          />
        </b-form-group>
      </b-col>
      <b-col class="pl-0" cols="6" md="4" sm="4">
        <b-form-group label="Поиск по email">
          <b-form-input
            v-model="emailSearch"
            debounce="500"
            placeholder="Поиск по email..."
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-col cols="12" md="3" sm="12">
      <b-card>
        <b-input-group>
          <b-form-input
            v-model="newBrokerCompany.name"
            placeholder="Название брокера"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="createBrokerCompany"
              >Добавить
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-card>
      <b-card>
        <b-list-group>
          <template v-for="company in brokerCompanies">
            <b-list-group-item
              :key="company.id"
              v-b-hover
              :active="selectedCompany === company.id"
              @click="selectedCompany = company.id"
            >
              {{ company.name }}
            </b-list-group-item>
          </template>
        </b-list-group>
      </b-card>
    </b-col>
    <b-col cols="12" md="9" sm="12">
      <b-alert v-if="!selectedCompany" class="p-2" show>
        Выберите компанию-брокера
      </b-alert>
      <template v-else>
        <b-card>
          <b-input-group>
            <b-form-input v-model="newBroker.first_name" placeholder="Имя" />
            <b-form-input v-model="newBroker.last_name" placeholder="Фамилия" />
            <b-form-input v-model="newBroker.email" placeholder="Email" />
            <b-form-input v-model="newBroker.phone" placeholder="Телефон" />
            <b-form-input v-model="newBroker.city" placeholder="Город" />
            <b-input-group-append>
              <b-button variant="primary" @click="createBroker"
                >Добавить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-card>
        <b-card>
          <b-table
            :fields="brokerFields"
            :items="brokers"
            responsive
            show-empty
            @row-clicked="openModal"
          >
            <template #head(first_name)="data">
              <span @click="toggleOrdering('first_name')">
                {{ data.label }} <b-icon-sort-alpha-down />
              </span>
            </template>
            <template #head(last_name)="data">
              <span @click="toggleOrdering('last_name')">
                {{ data.label }} <b-icon-sort-alpha-down />
              </span>
            </template>
            <template #head(city)="data">
              <span @click="toggleOrdering('city')">
                {{ data.label }} <b-icon-sort-alpha-down />
              </span>
            </template>
            <template #cell(action)="data">
              <b-checkbox
                v-model="data.item.is_active"
                @change="updateBroker(data.item)"
              />
            </template>
          </b-table>
        </b-card>
      </template>
    </b-col>
    <broker-modal :broker-data="selectedBroker" @brokerUpdated="getBrokers" />
  </b-row>
</template>

<script>
import BrokerModal from '@/views/brokers/components/BrokerModal'

export default {
  name: 'BrokersList',
  components: { BrokerModal },
  data() {
    return {
      selectedBroker: {},
      phoneSearch: '',
      emailSearch: '',
      firstNameSearch: '',
      lastNameSearch: '',
      citySearch: '',
      ordering: '',
      newBrokerCompany: {},
      newBroker: {},
      brokerCompanies: [],
      brokers: [],
      brokerFields: [
        { key: 'first_name', label: 'Имя' },
        { key: 'last_name', label: 'Фамилия' },
        { key: 'city', label: 'Город' },
        { key: 'phone', label: 'Телефон' },
        { key: 'email', label: 'Email' },
        { key: 'action', label: 'Активный?' },
      ],
      selectedCompany: null,
    }
  },
  watch: {
    selectedCompany() {
      this.getBrokers()
    },
    phoneSearch() {
      this.getBrokers()
    },
    firstNameSearch() {
      this.getBrokers()
    },
    lastNameSearch() {
      this.getBrokers()
    },
    emailSearch() {
      this.getBrokers()
    },
    citySearch() {
      this.getBrokers()
    },
    ordering() {
      this.getBrokers()
    },
  },
  created() {
    this.getBrokerCompanies()
  },
  methods: {
    async getBrokerCompanies() {
      const res = await this.$http.get('admin/broker-companies')
      this.brokerCompanies = res.data.results
    },
    async getBrokers() {
      const res = await this.$http.get('admin/brokers/', {
        params: {
          company: this.selectedCompany,
          ordering: this.ordering,
          phone__icontains: this.phoneSearch,
          email__icontains: this.emailSearch,
          first_name__icontains: this.firstNameSearch,
          last_name__icontains: this.lastNameSearch,
          city__icontains: this.citySearch,
        },
      })
      this.brokers = res.data.results
    },
    async createBrokerCompany() {
      try {
        await this.$http.post('admin/broker-companies/', this.newBrokerCompany)
        await this.getBrokerCompanies()
      } catch (e) {
        alert(`Error: ${e.response.data}`)
      }
    },
    async createBroker() {
      try {
        this.newBroker.company = this.selectedCompany
        await this.$http.post('admin/brokers/', this.newBroker)
        await this.getBrokers()
        this.newBroker = {}
      } catch (e) {
        alert(`Error: ${e.response.data}`)
      }
    },
    async updateBroker(broker) {
      try {
        await this.$http.patch(`admin/brokers/${broker.id}/`, broker)
        await this.getBrokers()
      } catch (e) {
        alert(`Error: ${e.response.data}`)
      }
    },
    toggleOrdering(name) {
      if (name.includes(this.ordering)) {
        this.ordering = `-${name}`
      } else {
        this.ordering = name
      }
    },
    openModal(item) {
      this.selectedBroker = item
      this.$bvModal.show('broker-modal')
    },
  },
}
</script>
